<template>
  <div>
    <h5 class="md:text-2xl text-xl text-center font-bold primary-text">
      Enter OTP
    </h5>
    <p class="text-center text-grey md:text-lg">
      We sent an SMS and a WhatsApp message to {{ phoneNumber }}
    </p>
    <form @submit.prevent="handleSubmitOtp">
      <OtpInput
        class="mt-8"
        @on-change="handleOnChange"
        @on-complete="handleOnChange"
      />
      <Spinner v-if="verifyLoading" />
      <div class="mt-4 flex" v-else>
        <p
          class="underline text-sm text-brandPurple cursor-pointer"
          v-if="showResendButton"
          @click="resendOtp"
        >
          Resend OTP
        </p>
        <div class="ml-auto" v-else>
          <vue-countdown
            :time="60 * 1000"
            v-slot="{ minutes, seconds }"
            @end="showResendButton = true"
          >
            <span class="text-sm text-grey">{{ minutes }}:{{ seconds }}</span>
          </vue-countdown>
        </div>

        <!-- </div> -->
      </div>
      <Button
        :disabled="disableButton"
        class="mt-8"
        width="w-full"
        type="submit"
        text="Complete verification"
        :loading="loading"
      />
    </form>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import { OtpInput } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import VueCountdown from "@chenfengyuan/vue-countdown";
  export default {
    components: {
      OtpInput,
      Button,
      Spinner: () => import("@/UI/Spinner"),
      "vue-countdown": VueCountdown,
    },
    props: {
      phoneNumber: {
        type: String,
        required: true,
        default: "",
      },
    },
    data: () => ({
      loading: false,
      verifyLoading: false,
      showResendButton: false,
      user: {
        otp: "",
      },
    }),
    computed: {
      rules() {
        return {
          otp: this.user.otp.length === 6,
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    mounted() {
      this.handleDelayOtpButton();
    },
    methods: {
      ...mapActions("userInfo", ["verifyPhone", "initiatePhoneVerification"]),
      ...mapActions("dashboard", ["getDashboardData"]),
      ...mapActions("notification", ["showToast"]),
      handleSubmitOtp() {
        this.loading = true;
        this.user.phone = this.phoneNumber;
        this.verifyPhone(this.user)
          .then(() => {
            this.getDashboardData().then(() => {
              this.loading = false;
              this.$emit("next");
            });
          })
          .catch(() => {
            this.loading = false;
          });
      },
      handleOnChange(value) {
        this.user.otp = value;
      },
      resendOtp() {
        this.verifyLoading = true;
        this.initiatePhoneVerification(this.phoneNumber)
          .then(() => {
            this.verifyLoading = false;
            this.showToast({
              display: true,
              icon: "success",
              description: "A new otp has been sent to your email",
            });
            this.showResendButton = false;
            this.handleDelayOtpButton();
          })
          .catch(() => {
            this.verifyLoading = false;
          });
      },
      handleDelayOtpButton() {
        setTimeout(() => {
          this.showResendButton = true;
        }, 60000);
      },
    },
  };
</script>
