var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "h5",
      { staticClass: "md:text-2xl text-xl text-center font-bold primary-text" },
      [_vm._v(" Enter OTP ")]
    ),
    _c("p", { staticClass: "text-center text-grey md:text-lg" }, [
      _vm._v(
        " We sent an SMS and a WhatsApp message to " +
          _vm._s(_vm.phoneNumber) +
          " "
      ),
    ]),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmitOtp.apply(null, arguments)
          },
        },
      },
      [
        _c("OtpInput", {
          staticClass: "mt-8",
          on: {
            "on-change": _vm.handleOnChange,
            "on-complete": _vm.handleOnChange,
          },
        }),
        _vm.verifyLoading
          ? _c("Spinner")
          : _c("div", { staticClass: "mt-4 flex" }, [
              _vm.showResendButton
                ? _c(
                    "p",
                    {
                      staticClass:
                        "underline text-sm text-brandPurple cursor-pointer",
                      on: { click: _vm.resendOtp },
                    },
                    [_vm._v(" Resend OTP ")]
                  )
                : _c(
                    "div",
                    { staticClass: "ml-auto" },
                    [
                      _c("vue-countdown", {
                        attrs: { time: 60 * 1000 },
                        on: {
                          end: function ($event) {
                            _vm.showResendButton = true
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var minutes = ref.minutes
                              var seconds = ref.seconds
                              return [
                                _c(
                                  "span",
                                  { staticClass: "text-sm text-grey" },
                                  [
                                    _vm._v(
                                      _vm._s(minutes) + ":" + _vm._s(seconds)
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
            ]),
        _c("Button", {
          staticClass: "mt-8",
          attrs: {
            disabled: _vm.disableButton,
            width: "w-full",
            type: "submit",
            text: "Complete verification",
            loading: _vm.loading,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }